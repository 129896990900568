import { Component, ElementRef, Input, Renderer2 } from '@angular/core';

@Component({
  selector: 'cp-par-hotkey-text',
  template: '',
  standalone: true,
})
export class HotkeyTextComponent {
  private readonly _element: HTMLElement;

  private _highlightedFor: string = '';

  @Input()
  text: string = '';

  constructor(
    _elementRef: ElementRef,
    private renderer: Renderer2,
  ) {
    this._element = _elementRef.nativeElement;
  }

  get highlightedFor(): string {
    return this._highlightedFor;
  }
  set highlightedFor(value: string) {
    if (value) {
      this._highlightedFor = value;
      this.highlightCharacter(value);
    }
  }

  private highlightCharacter(character: string): void {
    if (!character || this.text.toLowerCase().indexOf(character) == -1) {
      this.renderer.appendChild(
        this._element,
        this.renderer.createText(this.text),
      );
      return;
    }

    character = this.adjustCharacterCase(character);
    const parts = this.text.split(character);

    if (parts.length !== 2) return;

    //Create the underlined character
    const underlinedChild = this.renderer.createElement('u');
    this.renderer.appendChild(
      underlinedChild,
      this.renderer.createText(character),
    );

    //Append all of them
    this.renderer.appendChild(
      this._element,
      this.renderer.createText(parts[0]),
    );
    this.renderer.appendChild(this._element, underlinedChild);
    this.renderer.appendChild(
      this._element,
      this.renderer.createText(parts[1]),
    );
  }
  private adjustCharacterCase(character: string): string {
    const upperCase = character.toUpperCase();
    const lowerCase = character.toLowerCase();
    const lowerCaseIndex = this.text.indexOf(lowerCase);
    const upperCaseIndex = this.text.indexOf(upperCase);

    if (lowerCaseIndex == -1) return upperCase;
    if (upperCaseIndex == -1) return lowerCase;

    return lowerCaseIndex < upperCaseIndex ? lowerCase : upperCase;
  }
}
