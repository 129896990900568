import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressService } from '../../../services/assessment/progress.service';
import { PresentationItemTracker } from '../../../models/presentation-item/presentation-item-tracker';
import { SelectionService } from '../../../services/assessment/selection.service';
import { PresentationItem } from '../../../models/assessment/presentation-item';
import { NgObjectPipesModule } from 'ngx-pipes';
import { PresentationItemType } from '../../../models/assessment/presentation-item-type';
import { OptionSpacing } from '../../../models/assessment/option-spacing';
import { OptionAlignment } from '../../../models/assessment/option-alignment';

@Component({
  selector: 'cp-par-debug-presentation-item-info',
  standalone: true,
  imports: [CommonModule, NgObjectPipesModule],
  templateUrl: './debug-presentation-item-info.component.html',
})
export class DebugPresentationItemInfoComponent {
  @Input({ required: true })
  presentationItem!: PresentationItem;

  get currentItemProgress(): PresentationItemTracker {
    return this.progress.getItemProgress(this.presentationItem.questionId);
  }

  constructor(
    protected readonly progress: ProgressService,
    private readonly _selection: SelectionService,
  ) {}

  protected isOptionSelected(questionOptionId: number): boolean {
    return this._selection
      .getForQuestion(this.presentationItem.questionId)
      .some(o => o.questionOptionId === questionOptionId);
  }

  protected getItemValue(key: keyof PresentationItem): unknown {
    const objectValue = this.presentationItem[key];
    switch (key) {
      case 'presentationItemType':
        return PresentationItemType[objectValue as number];
      case 'optionSpacing':
        return OptionSpacing[objectValue as number];
      case 'optionAlignment':
        return OptionAlignment[objectValue as number];
      default:
        return objectValue;
    }
  }
}
