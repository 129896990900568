import { Interval } from '@compass/helpers';

export class PresentationItemTracker {
  private readonly _interval = Interval.fromSeconds(
    () => this._secondsVisibleCount++,
    1,
  );

  get secondsVisibleCount(): number {
    return this._secondsVisibleCount;
  }

  get shownToParticipantCount(): number {
    return this._shownToParticipantCount;
  }

  constructor(
    public readonly questionId: number,
    private _shownToParticipantCount: number = 0,
    private _secondsVisibleCount: number = 0,
  ) {}

  startTracking(canIncrementViewCount: boolean): void {
    if (this._interval.isRunning) return;

    if (canIncrementViewCount) this.addView();

    this._secondsVisibleCount++;

    this._interval.start();
  }

  addView(): void {
    this._shownToParticipantCount++;
  }

  stopTracking(): void {
    this._interval.stop();
  }
}
