import { Injectable, signal, WritableSignal } from '@angular/core';
import { ContentGroup } from '../../models/assessment/content-group';
import { PresentationItem } from '../../models/assessment/presentation-item';
import { Logger } from '@compass/logging';
import { Subject } from 'rxjs';
import { ContentClient } from '../../clients/content.client';

export interface ContentGroupChangeArgs {
  oldGroup?: ContentGroup;
  newGroup: ContentGroup;
}

export interface PresentationItemChangeArgs {
  oldItem?: PresentationItem;
  newItem: PresentationItem;
}

export type PresentationInfo = {
  presentationItem: PresentationItem;
  contentGroup: ContentGroup;
  isFirstInAssessment: boolean;
  isFirstInGroup: boolean;
  isLastInAssessment: boolean;
  isLastInGroup: boolean;
};

export type CurrentPresentationInfo = PresentationInfo & {
  previous: Partial<PresentationInfo>;
  next: Partial<PresentationInfo>;
};

@Injectable({
  providedIn: 'root',
})
export class PresentationService {
  private readonly _contentGroupChanged = new Subject<ContentGroupChangeArgs>();
  private readonly _presentationItemChanged =
    new Subject<PresentationItemChangeArgs>();
  private readonly _presentationItems: CurrentPresentationInfo[] = [];

  private _currentPresentationIndex: number = 0;

  readonly contentGroupChange$ = this._contentGroupChanged.asObservable();
  readonly presentationItemChange$ =
    this._presentationItemChanged.asObservable();

  get currentPresentationItemIndex(): number {
    return this._currentPresentationIndex;
  }

  get current(): CurrentPresentationInfo {
    return this._presentationItems[this._currentPresentationIndex];
  }

  public hideChoices: WritableSignal<boolean> = signal(false);

  constructor(
    contentClient: ContentClient,
    private readonly _logger: Logger,
  ) {
    this._presentationItems = this.flattenContentItems(
      contentClient.data.content,
    );

    this._currentPresentationIndex = this.getStartIndex(
      contentClient.data.progress?.presentationItemPointer,
    );

    this.contentItemChange(this._currentPresentationIndex);
  }

  goToGroup(groupSourceId: string): void {
    const index = this._presentationItems.findIndex(
      i => i.contentGroup.sourceId === groupSourceId,
    );

    if (index < 0) {
      this._logger.debug(
        `Refused to go to group [${groupSourceId}] because it was not found.`,
      );
      return;
    }

    this.goToIndex(index);
  }

  goToNext(): void {
    this.goToIndex(this._currentPresentationIndex + 1);
  }

  goToPrevious(): void {
    this.goToIndex(this._currentPresentationIndex - 1);
  }

  private goToIndex(index: number): void {
    if (index < 0 || index > this._presentationItems.length - 1) return;

    const oldIndex = this._currentPresentationIndex;

    this._currentPresentationIndex = index;

    this.contentItemChange(this._currentPresentationIndex, oldIndex);
  }

  private getStartIndex(itemPointer: number | undefined): number {
    if (!itemPointer) return 0;

    const itemIndex = this._presentationItems.findIndex(
      i => i.presentationItem.questionId === itemPointer,
    );

    return itemIndex >= 0 ? itemIndex : 0;
  }

  private contentItemChange(newIndex: number, oldIndex?: number): void {
    if (oldIndex === newIndex) return;

    const oldItem =
      oldIndex === undefined ? undefined : this._presentationItems[oldIndex];
    const newItem = this._presentationItems[newIndex];

    // Emit content group change
    if (
      !oldItem ||
      oldItem.contentGroup.sourceId !== newItem.contentGroup.sourceId
    ) {
      this._contentGroupChanged.next({
        newGroup: newItem.contentGroup,
        oldGroup: oldItem?.contentGroup,
      });
      this.logContentGroupChanged(newItem.contentGroup);
    }

    // Emit presentation item change
    this._presentationItemChanged.next({
      oldItem: oldItem?.presentationItem,
      newItem: newItem.presentationItem,
    });
    this.logItemChanged();
  }

  private flattenContentItems(
    contentGroups: ContentGroup[],
  ): CurrentPresentationInfo[] {
    const items: CurrentPresentationInfo[] = [];

    // Filter out empty groups
    contentGroups = contentGroups.filter(cg => cg.presentationItems.length > 0);

    for (let i = 0; i < contentGroups.length; i++) {
      const currentContentGroup = contentGroups[i];
      const nextContentGroup =
        i < contentGroups.length - 1 ? contentGroups[i + 1] : undefined;
      const previousContentGroup = i > 0 ? contentGroups[i - 1] : undefined;

      for (let j = 0; j < currentContentGroup.presentationItems.length; j++) {
        const currentPresentationItem =
          currentContentGroup.presentationItems[j];
        // Next item in the group OR first item in next group (if exists)
        const nextPresentationItem =
          j < currentContentGroup.presentationItems.length - 1
            ? currentContentGroup.presentationItems[j + 1]
            : nextContentGroup?.presentationItems[0];
        // Previous item in the group OR last item in previous group (if exists)
        const previousPresentationItem =
          j > 0
            ? currentContentGroup.presentationItems[j - 1]
            : previousContentGroup?.presentationItems.slice(-1)[0];

        items.push({
          contentGroup: currentContentGroup,
          presentationItem: currentPresentationItem,
          isFirstInAssessment: i === 0 && j === 0,
          isFirstInGroup: j === 0,
          isLastInAssessment:
            i === contentGroups.length - 1 &&
            j === currentContentGroup.presentationItems.length - 1,
          isLastInGroup: j === currentContentGroup.presentationItems.length - 1,
          next: {
            contentGroup: nextContentGroup,
            presentationItem: nextPresentationItem,
          },
          previous: {
            contentGroup: previousContentGroup,
            presentationItem: previousPresentationItem,
          },
        });
      }
    }

    return items;
  }

  private logItemChanged(): void {
    this._logger.debug(
      'Presentation item was loaded.',
      this.current.presentationItem,
    );
  }

  private logContentGroupChanged(contentGroup: ContentGroup): void {
    this._logger.debug('Content group was loaded.', contentGroup);
  }
}
