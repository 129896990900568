import {
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  Output,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[cpParNavigationItem]',
  standalone: true,
  exportAs: 'navigationItem',
})
export class NavigationItemDirective {
  private readonly _nativeElement: HTMLElement;

  private _highlighted: boolean = false;

  @Output()
  readonly navigationItemSelected = new EventEmitter();

  @HostBinding('class')
  readonly hostCssClass = 'navigation-item';

  get highlighted(): boolean {
    return this._highlighted;
  }

  set highlighted(value: boolean) {
    this._highlighted = value;
    this.highlight(value);
  }

  constructor(
    private _renderer: Renderer2,
    elementRef: ElementRef,
  ) {
    this._nativeElement = elementRef.nativeElement;
  }

  private highlight(value: boolean): void {
    if (value) {
      this._renderer.addClass(this._nativeElement, 'highlighted');
      if (!this.isInViewport()) {
        this._nativeElement.scrollIntoView();
      }
    } else {
      this._renderer.removeClass(this._nativeElement, 'highlighted');
    }
  }

  select(): void {
    this.navigationItemSelected.emit();
  }

  // https://www.javascripttutorial.net/dom/css/check-if-an-element-is-visible-in-the-viewport/
  private isInViewport(): boolean {
    const rect = this._nativeElement.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }
}
