<div class="row align-items-center">
  <div class="col">
    <div
      [cpParHotkey]="{ key: KeyboardKey.Slash, req: { requireAlt: true } }"
      class="navigation-help-text d-none d-md-block"
      (click)="sidebar.toggle()"
      (keyup)="sidebar.toggle()"
      tabindex="1">
      To view keyboard shortcuts press
      <kbd>{{ deviceInfo.device.isMac ? 'Option (⌥)' : 'Alt' }}</kbd>
      + <kbd>/</kbd>
    </div>
  </div>
  <div class="col-auto d-flex justify-content-center">
    <cp-par-calculator-button />
  </div>
  <div class="col d-flex justify-content-end">
    <cp-par-previous-button class="me-2" />
    <cp-par-next-button />
  </div>
</div>

<ejs-sidebar #sidebar type="Over" position="Right" class="text-dark p-2">
  <div class="container">
    <div class="row">
      <div class="col-auto">
        <span class="fs-4">Keyboard shortcuts</span>
      </div>
      <div class="col-auto">
        <button class="btn btn-link" (click)="sidebar.toggle()">Close</button>
      </div>
    </div>
  </div>

  <cp-par-keyboard-shortcuts-help />
</ejs-sidebar>
