import { Component, ComponentRef, Input, Optional } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { DialogOptions } from '../../types/dialog-options';
import {
  defaultDialogConfiguration,
  DialogConfig,
} from '../../types/dialog-config';
import { ModalHeaderComponent } from '../modal-header/modal-header.component';
import { DialogStyle } from '../../types/dialog-style';
import { Logger } from '@compass/logging';
import { SafeHtmlPipe } from '@compass/pipes';

@Component({
  selector: 'cp-dialog-outlet',
  templateUrl: './dialog-outlet.component.html',
  styleUrls: ['./dialog-outlet.component.scss'],
  standalone: true,
  imports: [CommonModule, DialogModule, ModalHeaderComponent, SafeHtmlPipe],
})
export class DialogOutletComponent {
  private _onDialogResolve?: (result: boolean) => void;

  @Input({ required: true })
  displayOptions!: DialogOptions;

  @Input({ required: true })
  instance!: ComponentRef<DialogOutletComponent>;

  @Input()
  dialogStyle: DialogStyle = 'normal';

  @Input()
  options: DialogConfig = defaultDialogConfiguration;

  protected confirmPending: boolean = false;

  constructor(
    @Optional()
    private readonly _logger?: Logger,
  ) {}

  onDialogResolve(callback: (result: boolean) => void): void {
    this._onDialogResolve = callback;
  }

  destroy(): void {
    this.destroyDialog(undefined);
  }

  cancel(): void {
    this.destroyDialog(false);
  }

  async confirm(): Promise<void> {
    if (!(await this.runBeforeConfirmCallback())) {
      this._logger?.debug(
        'Refused to close dialog because close was prevented by callback function.',
      );
      return;
    }

    this.destroyDialog(true);
  }

  protected onOverlayClicked(): void {
    if (!this.options.closeOnBackdropClick) return;

    this.cancel();
  }

  private destroyDialog(result?: boolean): void {
    if (!this.instance) {
      throw new Error(
        'The component instance was not provided and the component cannot destroy itself.',
      );
    }

    if (result !== undefined) {
      this._onDialogResolve?.(result);
    }

    this.instance.destroy();
  }

  private async runBeforeConfirmCallback(): Promise<boolean> {
    // If no callback is set then return true
    if (!this.options.beforeConfirm) return true;

    try {
      this.confirmPending = true;

      const callbackResult = this.options.beforeConfirm();

      // If it's synchronous function then return the value
      if (typeof callbackResult === 'boolean') {
        return callbackResult;
      }

      // If it's async then await the result and return it
      return await callbackResult;
    } catch (error) {
      this._logger?.error(
        'An error occurred in a callback before dialog confirmation.',
        error,
      );

      return false;
    } finally {
      this.confirmPending = false;
    }
  }
}
