import {
  ContentChildren,
  Directive,
  OnDestroy,
  OnInit,
  QueryList,
} from '@angular/core';
import { NavigationItemDirective } from './navigation-item.directive';
import { Subscription } from 'rxjs';
import { KeyboardKey, KeyboardService } from '../services/ui/keyboard.service';

@Directive({
  selector: '[cpParNavigationGroup]',
  standalone: true,
})
export class NavigationGroupDirective implements OnInit, OnDestroy {
  @ContentChildren(NavigationItemDirective, { descendants: true })
  set navigationItems(value: QueryList<NavigationItemDirective>) {
    this._groupItems = value.toArray();
    this._currentIndex = -1;
  }

  private _groupItems: NavigationItemDirective[] = [];

  private _keyUpSubscription?: Subscription;
  private _currentIndex: number = -1;

  constructor(private _keyboardService: KeyboardService) {}

  ngOnInit(): void {
    this._keyUpSubscription = this._keyboardService.onKeyUp$.subscribe(
      keyboardEvent => {
        switch (keyboardEvent.key) {
          case KeyboardKey.ArrowUp:
            this.movePrevious();
            break;
          case KeyboardKey.ArrowDown:
            this.moveNext();
            break;
          case KeyboardKey.Enter:
          case KeyboardKey.Space:
            this.select();
            break;
          default:
            return;
        }

        keyboardEvent.event.preventDefault();
      },
    );
  }

  ngOnDestroy(): void {
    this._keyUpSubscription?.unsubscribe();
  }

  private moveNext(): void {
    if (!this.hasItems()) return;

    this._currentIndex++;
    if (this._currentIndex >= this._groupItems.length) {
      this._currentIndex = 0;
    }

    this.highlightCurrent();
  }

  private movePrevious(): void {
    if (!this.hasItems()) return;

    this._currentIndex--;
    if (this._currentIndex < 0) {
      this._currentIndex = this._groupItems.length - 1;
    }

    this.highlightCurrent();
  }

  private select(): void {
    if (
      this._currentIndex >= 0 &&
      this._currentIndex < this._groupItems.length
    ) {
      this._groupItems[this._currentIndex].select();
    }
  }

  private highlightCurrent(): void {
    for (let i = 0; i < this._groupItems.length; i++) {
      this._groupItems[i].highlighted = this._currentIndex === i;
    }
  }

  private hasItems(): boolean {
    return this._groupItems.length > 0;
  }
}
