import { Component, Input, OnInit, Type } from '@angular/core';
import { CdkDragEnd, Point } from '@angular/cdk/drag-drop';
import { Subject } from 'rxjs';

@Component({
  selector: 'cp-debug-outlet',
  templateUrl: './debug-outlet.component.html',
  styleUrls: ['./debug-outlet.component.scss'],
})
export class DebugOutletComponent implements OnInit {
  private _visible: boolean = false;
  private _stateUpdated = new Subject<void>();
  private _currentPosition: Point = { x: 0, y: 0 };

  readonly stateUpdated = this._stateUpdated.asObservable();

  @Input({ required: true })
  debugComponent!: Type<unknown>;

  @Input()
  initialConfig?: {
    windowVisible?: boolean;
    position?: Point;
  };

  get currentPosition(): Point {
    return this._currentPosition;
  }

  get visible(): boolean {
    return this._visible;
  }

  ngOnInit(): void {
    if (!this.initialConfig) return;

    if (this.initialConfig.position) {
      this._currentPosition = this.initialConfig.position;
    }

    if (this.initialConfig.windowVisible) {
      this._visible = this.initialConfig.windowVisible;
    }
  }

  show(): void {
    this._visible = true;

    this._stateUpdated.next();
  }

  hide(): void {
    this._visible = false;

    this._stateUpdated.next();
  }

  dragEnd(eventArgs: CdkDragEnd): void {
    this._currentPosition.x += eventArgs.distance.x;
    this._currentPosition.y += eventArgs.distance.y;

    this._stateUpdated.next();
  }
}
