import { Injectable, OnDestroy } from '@angular/core';
import { Logger } from '@compass/logging';
import { ProgressService } from '../assessment/progress.service';
import { SelectionService } from '../assessment/selection.service';
import { Subscription, merge } from 'rxjs';
import { Progress } from '../../models/assessment/progress';
import {
  PresentationItemChangeArgs,
  PresentationService,
} from '../assessment/presentation.service';
import { PresentationItemState } from '../../models/assessment/presentation-item-state';
import { TimerService } from '../assessment/timer.service';
import { ContentGroupTimer } from '../../models/assessment/content-group-timer';
import { TimerState } from '../../models/assessment/timer-state';
import { unique } from '@compass/helpers';
import { AssessmentContentService } from '../assessment/assessment-content.service';
import { Requester } from '@compass/http';

const SAVE_COOLDOWN_MS = 1_500;
const SAVE_INTERVAL_MS = 15_000;
const SAVE_MAX_ERRORS = 5;

/**
 * Service responsible for managing and persisting progress data for participants.
 *
 * @implements {OnDestroy}
 */
@Injectable({
  providedIn: 'root',
})
export class ProgressStoreService implements OnDestroy {
  // Arrays tracking which items & timers are dirty
  private _presentationIdsToUpdate: number[] = [];
  private _timerIdsToUpdate: string[] = [];

  // Subscription variables
  private _presentationItemChangeSub?: Subscription;
  private _timerActionSub?: Subscription;
  private _autoSaveInterval?: unknown;
  private _coolDownTimeout?: unknown;

  // Save progress tracking
  private _saveInProgress = false;
  private _savePending = false;
  private _coolDownPending = false;
  private _errors = 0;

  get isTrackingEnabled(): boolean {
    // If we are autosaving then it's not enabled
    return this._autoSaveInterval !== undefined;
  }

  constructor(
    private readonly _logger: Logger,
    private readonly _progress: ProgressService,
    private readonly _selection: SelectionService,
    private readonly _presentation: PresentationService,
    private readonly _timer: TimerService,
    private readonly _content: AssessmentContentService,
    private readonly _requester: Requester,
  ) {}

  ngOnDestroy(): void {
    this.stopProgressTracking();
  }

  /**
   * Starts tracking and automatically persisting the progress.
   *
   * @return {void}
   */
  startProgressTracking(): void {
    if (this.isTrackingEnabled) {
      this.stopProgressTracking();
    }

    this._presentationItemChangeSub =
      this._presentation.presentationItemChange$.subscribe(
        this.onPresentationItemChanged.bind(this),
      );

    this._timerActionSub = merge(
      this._timer.timerStarted$,
      this._timer.timerStopped$,
    ).subscribe(this.onTimerUpdated.bind(this));

    this._autoSaveInterval = setInterval(
      this.save.bind(this),
      SAVE_INTERVAL_MS,
    );

    this._logger.debug('Started tracking user progress.');
  }

  /**
   * Stops tracking and automatically persisting the progress.
   *
   * @return {void}
   */
  stopProgressTracking(): void {
    if (!this.isTrackingEnabled) return;

    // Clear intervals & timeouts
    clearInterval(this._autoSaveInterval as never);
    clearTimeout(this._coolDownTimeout as never);

    this._autoSaveInterval = undefined;
    this._coolDownTimeout = undefined;

    // Unsubscribe from everything
    this._presentationItemChangeSub?.unsubscribe();
    this._timerActionSub?.unsubscribe();

    // Reset tracking variables
    this._saveInProgress = false;
    this._savePending = false;
    this._coolDownPending = false;
    this._errors = 0;

    this._logger.debug('Stopped tracking user progress.');
  }

  progressForAll(): Progress {
    // Get all presentation item IDs
    const itemIds = this._content.contentGroups.reduce(
      (result, contentGroup) => {
        result.push(...contentGroup.presentationItems.map(i => i.questionId));

        return result;
      },
      [] as number[],
    );

    // Get all timer IDs
    const timerIds = this._content.timers.map(t => t.sourceId);

    // Collect the progress for all the items and timers
    return this.collectProgress(itemIds, timerIds);
  }

  /**
   * Saves the current progress of the assessment.
   *
   * @return {void}
   */
  save(): void {
    // Check if there is a save operation in progress - if there is then
    // mark queue the new operation for processing after the current one finished
    if (this._saveInProgress) {
      this._savePending = true;

      this._logger.debug(
        'Save was requested, but another save operation is in progress. This operation will be queued.',
      );

      return;
    }

    // If we are in cooldown period then enqueue this operation and exit
    if (this._coolDownPending) {
      this._savePending = true;

      this._logger.debug(
        `Save was requested too soon after the previous operation. This request will be fulfilled after a cooldown period of ${SAVE_COOLDOWN_MS}ms.`,
      );

      return;
    }

    this.markSaveInProgress();

    const progress = this.collectProgress(
      this._presentationIdsToUpdate,
      this._timerIdsToUpdate,
    );

    this.resetPendingUpdateItems();

    this._logger.debug('Sending assessment progress to the server.', progress);

    this._requester
      .put<void>('content')
      .send(progress)
      .then(this.onSaveSuccess.bind(this))
      .catch(this.onSaveFail.bind(this))
      .finally(() => (this._saveInProgress = false));
  }

  private onSaveSuccess(): void {
    this._errors = 0;

    if (!this._savePending) return;

    this._savePending = false;
    this.save();
  }

  private onSaveFail(error: unknown): void {
    this._errors++;

    this._logger.error('Failed to save participant progress.', error);

    if (this._errors < SAVE_MAX_ERRORS) return;

    this._logger.warn(
      `Reached maximum number (${SAVE_MAX_ERRORS}) consecutive errors. Progress tracking will not be persisted anymore.`,
    );

    this.stopProgressTracking();
  }

  private markSaveInProgress(): void {
    this._saveInProgress = true;
    this._savePending = false;

    this.startCoolDown();
  }

  private startCoolDown(): void {
    this._coolDownPending = true;

    if (this._coolDownTimeout) {
      clearTimeout(this._coolDownTimeout as never);
    }

    this._coolDownTimeout = setTimeout(() => {
      this._coolDownPending = false;

      if (this._savePending) {
        this.save();
      }
    }, SAVE_COOLDOWN_MS);
  }

  private resetPendingUpdateItems(): void {
    const timer = this._timer.timerForCurrentGroup;

    this._presentationIdsToUpdate = [
      this._presentation.current.presentationItem.questionId,
    ];
    this._timerIdsToUpdate = timer ? [timer.timerId] : [];
  }

  private collectProgress(
    presentationIdsToUpdate: number[],
    timerIdsToUpdate: string[],
  ): Progress {
    return {
      presentationItemPointer:
        this._presentation.current.presentationItem.questionId,
      presentationItemStates: this.collectPresentationItemStates(
        presentationIdsToUpdate,
      ),
      timerStates: this.collectTimerStates(timerIdsToUpdate),
    };
  }

  private collectPresentationItemStates(
    questionIds: number[],
  ): PresentationItemState[] {
    const states: PresentationItemState[] = [];

    for (const questionId of unique(questionIds)) {
      const progress = this._progress.getItemProgress(questionId);
      const selections = this._selection.getForQuestion(questionId);

      states.push({
        questionId,
        secondsVisibleCount: progress.secondsVisibleCount,
        shownToCandidateCount: progress.shownToParticipantCount,
        selections: selections.map(o => o.questionOptionId),
      });
    }

    return states;
  }

  private collectTimerStates(timerIds: string[]): TimerState[] {
    const states: TimerState[] = [];

    for (const timerSourceId of unique(timerIds)) {
      const timer = this._timer.getTimer(timerSourceId);

      if (!timer) continue;

      states.push({
        timerSourceId,
        secondsElapsed: timer.numberOfSecondsElapsed,
      });
    }

    return states;
  }

  private onTimerUpdated(timer: ContentGroupTimer): void {
    this._timerIdsToUpdate.push(timer.sourceId);

    this.save();
  }

  private onPresentationItemChanged(change: PresentationItemChangeArgs): void {
    this._presentationIdsToUpdate.push(change.newItem.questionId);

    if (change.oldItem) {
      this._presentationIdsToUpdate.push(change.oldItem.questionId);
    }

    this.save();
  }
}
